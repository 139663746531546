/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  display: flex;
}
.base .drawerPane {
  position: relative;
  flex-shrink: 0;
}
.base .mainPane {
  flex-grow: 1;
  width: auto;
  min-width: 0;
}
.base .mainPane .headerBlock {
  max-width: 100vw;
}
.base .mainPane .headerBlock.pinned {
  position: sticky;
  top: 64px;
  z-index: 200;
}
.base .mainPane .contentBlock {
  padding: 20px 0 60px;
  min-height: 100vh;
  max-width: 100%;
  min-width: 0;
}
@media (max-width: 599px) {
  .base .mainPane .contentBlock {
    padding: 15px 0 60px;
  }
}
@media (max-width: 1199px) {
  .base .drawerPane {
    width: 0;
  }
}