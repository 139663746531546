/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  padding: 15px;
  padding-right: 47px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #1761a6;
  border-radius: 5px 5px 0 0;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.base .icon,
.base .image {
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid #ffffff;
}
@media (max-width: 599px) {
  .base .icon,
.base .image {
    height: 44px;
    width: 44px;
  }
}
.base .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.base .icon svg {
  fill: #ffffff;
}
.base .icon svg {
  width: 28px;
  height: 28px;
}
@media (max-width: 599px) {
  .base .icon svg {
    width: 24px;
    height: 24px;
  }
}
.base .titles {
  flex-grow: 1;
  text-align: left;
  min-width: 0;
}
.base .titles .title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 599px) {
  .base .titles .title {
    font-size: 18px;
  }
}
.base .titles .subtitle {
  font-size: 14px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  margin-top: -2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base.alignCenter {
  text-align: center;
}
.base.sizeMedium {
  font-size: 30px;
}
.base.typeError {
  background-color: #d04b67;
}