/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  border: 1px solid rgba(58, 77, 96, 0.25);
  color: #3a4d60;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.base:hover {
  border-color: #1761a6;
  box-shadow: inset 0 0 0 1px #1761a6;
}
.base:active {
  border-color: rgba(58, 77, 96, 0.1);
  background-color: #f2f2f2;
}
.base .symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 45%;
  margin-top: 10%;
  margin-bottom: 10%;
}
.base .label {
  font-size: 16px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 10% 10%;
}