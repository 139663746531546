/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PAGE BAR
   =================================================================== */
.base {
  position: sticky;
  bottom: 10px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.06);
  z-index: 200;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.06);
  margin-top: 20px;
  border: 2px solid #1761a6;
}
.base .wrapper {
  flex-grow: 1;
  white-space: nowrap;
}
@media (max-width: 899px) {
  .base {
    left: 0;
  }
}