/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ALERT
   =================================================================== */
.base {
  padding: 10px;
  border-radius: 3px;
  border: 1px dashed rgba(58, 77, 96, 0.1);
  background-color: rgba(58, 77, 96, 0.03);
  color: rgba(58, 77, 96, 0.8);
  display: flex;
}
.base.info {
  background-color: rgba(74, 161, 219, 0.1);
  border-color: rgba(74, 161, 219, 0.4);
  color: #6b9aba;
}
.base.info .icon svg {
  fill: #4aa1db;
}
.base.success {
  background-color: rgba(54, 177, 137, 0.1);
  border-color: rgba(54, 177, 137, 0.4);
  color: #598e7d;
}
.base.success .icon svg {
  fill: #36b189;
}
.base.warning {
  background-color: rgba(255, 184, 34, 0.1);
  border-color: rgba(238, 162, 0, 0.5);
  color: #d59000;
}
.base.warning .icon svg {
  fill: #ffb822;
}
.base.error {
  background-color: rgba(208, 75, 103, 0.1);
  border-color: rgba(208, 75, 103, 0.4);
  color: #ae6d7b;
}
.base.error .icon svg {
  fill: #d04b67;
}
.base.note {
  background-color: #ffffed;
  border-color: rgba(119, 117, 85, 0.2);
  color: #777555;
}
.base.note .icon svg {
  fill: #ffffed;
}
.base .icon {
  padding-right: 10px;
}
.base .icon svg {
  width: 20px;
  height: 20px;
}
.base .icon svg {
  fill: rgba(58, 77, 96, 0.8);
}
.base .text {
  flex-grow: 1;
}
.base .text .title {
  margin-bottom: 5px;
  font-weight: 600;
}