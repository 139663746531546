/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.controls {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px;
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
  background: #ffffff;
}