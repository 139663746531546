/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BOX
   =================================================================== */
.base {
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.06);
  display: flex;
  width: 100%;
  flex-direction: column;
}
.base .title {
  display: flex;
  text-align: left;
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
  overflow: hidden;
}
.base .title .icon {
  min-height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-right: -10px;
}
.base .title .icon svg {
  fill: #1761a6;
}
.base .title .label {
  padding: 17px 10px 15px 20px;
  font-weight: 600;
  font-size: 16px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .title .badge {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.base .title .actions {
  margin: 0;
  padding: 0;
  list-style: none;
  flex-shrink: 0;
}
.base .title .actions li {
  height: 100%;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  white-space: nowrap;
}
.base .title .actions li .caret {
  transform: rotate(0deg);
}
.base .title .actions li .caret svg {
  width: 12px;
  height: 12px;
}
.base .title .actions li .caret svg {
  fill: rgba(58, 77, 96, 0.3);
}
.base .title .actions li:hover .caret svg {
  fill: #1761a6;
}
.base .title .actions li.collapseButton {
  cursor: pointer;
  border-left: 1px solid rgba(58, 77, 96, 0.1);
  width: 52px;
}
.base .title .actions li.collapseButton:active {
  background-color: rgba(58, 77, 96, 0.03);
}
.base .title.underTitle {
  background-color: rgba(234, 240, 245, 0.3);
}
.base .title.underTitle .label {
  padding: 6px 20px 5px;
  font-size: 14px;
}
.base .rank {
  display: flex;
  background-color: rgba(58, 77, 96, 0.05);
  text-align: center;
  margin: 3px 3px 0 3px;
  border-radius: 2px;
}
.base .rank .label {
  padding: 5px 10px 4px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .rank.low {
  background-color: #47c680;
  color: #ffffff;
}
.base .rank.mid {
  background-color: #ff9100;
  color: #ffffff;
}
.base .rank.high {
  background-color: #e02447;
  color: #ffffff;
}
.base .rank.missing {
  background: transparent;
  color: #d04b67;
  border: 1px dashed rgba(208, 75, 103, 0.4);
}
.base .content {
  position: relative;
}
.base .content .wrapper {
  padding: 20px;
}
.base .content.borderTop {
  border-top: 1px solid rgba(58, 77, 96, 0.1);
}
.base .content.borderBottom {
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
}
.base .content.borderRight {
  border-right: 1px solid rgba(58, 77, 96, 0.1);
}
.base .content.borderLeft {
  border-left: 1px solid rgba(58, 77, 96, 0.1);
}
.base .content.paddingNone .wrapper {
  padding: 0;
}
.base .content.paddingSmall .wrapper {
  padding: 10px;
}
.base .content.grow {
  flex-grow: 1;
}
.base .content.colorNote {
  background-color: #ffffed;
  color: #777555;
}
.base .content.colorAttention {
  animation: rowBlink 2s ease-in-out none 20;
}
@keyframes rowBlink {
  0% {
    background-color: rgba(246, 253, 154, 0.5);
  }
  50% {
    background-color: #f6fd9a;
  }
  100% {
    background-color: rgba(246, 253, 154, 0.5);
  }
}
.base .content.colorSuccess {
  background-color: rgba(54, 177, 137, 0.3);
  color: #3c7864;
}
.base .content.colorError {
  background-color: rgba(208, 75, 103, 0.25);
  color: #a1475a;
}
.base .content.colorSilent {
  background-color: rgba(234, 240, 245, 0.7);
}
.base .footer {
  border-top: 1px solid rgba(58, 77, 96, 0.1);
}
.base .footer .wrapper {
  padding: 20px;
}
.base.fill {
  min-height: 100%;
}
.base.collapsed .title {
  margin-bottom: -1px;
  border-bottom-color: transparent;
}
.base.collapsed .title .actions li .caret {
  transform: rotate(-90deg);
}
.base.collapsed .content {
  overflow: hidden;
  height: 0 !important;
}
.base.clickable:hover {
  cursor: pointer;
}
.base.clickable:active {
  box-shadow: none;
}