/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   MENU
   =================================================================== */
.base {
  display: block;
  background-color: #ffffff;
  width: 100%;
}
.base.hidden {
  display: none;
}

.heading {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 31px;
  line-height: 31px;
  padding: 0 10px;
  font-weight: 700;
  color: rgba(58, 77, 96, 0.6);
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.3px;
  border-bottom: 1px solid rgba(58, 77, 96, 0.05);
}

.item {
  position: relative;
  display: flex;
  width: 100%;
  height: 42px;
  padding: 0px 10px;
  outline: none;
  transition: color 0.2s ease-in-out;
  user-select: none;
  border-bottom: 1px solid rgba(58, 77, 96, 0.05);
}
.item:last-child {
  border-bottom: none;
}
.item .indicators {
  display: flex;
  align-items: center;
}
.item .icon {
  display: flex;
  align-items: center;
  width: 32px;
}
.item .symbol {
  display: flex;
  align-items: center;
  width: 32px;
}
.item .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: #3a4d60;
  text-align: left;
  font-family: "Catamaran", sans-serif;
  line-height: 42px;
  flex-grow: 1;
  padding-top: 1px;
}
.item .counter {
  display: inline-block;
  line-height: 1;
  min-width: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  background-color: #d04b67;
  padding: 3px 5px;
  border-radius: 20px;
}
.item .arrow svg {
  width: 8px;
  height: 8px;
}
.item .arrow svg {
  fill: transparent;
}
.item:hover {
  cursor: pointer;
  background: rgba(58, 77, 96, 0.03);
  color: #3a4d60;
}
.item:active {
  background-color: #ffffff;
}
.item.selected {
  background-color: rgba(58, 77, 96, 0.03);
}
.item.selected .icon svg {
  fill: rgba(58, 77, 96, 0.4);
}
.item.selected .label {
  color: rgba(58, 77, 96, 0.8);
}
.item.selected:hover {
  background-color: rgba(58, 77, 96, 0.03);
}
.item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.item.disabled .label {
  color: rgba(58, 77, 96, 0.6);
}
.item.disabled:hover, .item.disabled:active {
  background-color: transparent;
}
.item.hidden {
  display: none;
}

.base.medium .item {
  height: 50px;
  padding: 0px 15px;
}
.base.medium .item .label {
  line-height: 50px;
}

/* Menu on primary background
---------------------------------------------------------------------- */
.primary {
  background: transparent;
}
.primary .heading {
  padding: 0 25px;
  background: transparent;
  color: rgba(0, 0, 0, 0.45);
  border-bottom: none;
}
.primary .item {
  background-color: transparent;
  border: none;
  padding: 0 25px;
}
.primary .item .icon svg {
  fill: rgba(255, 255, 255, 0.3);
  transition: fill 0.2s ease;
}
.primary .item .label {
  color: rgba(255, 255, 255, 0.8);
}
.primary .item .arrow svg {
  fill: rgba(255, 255, 255, 0.3);
  transition: fill 0.2s ease;
}
.primary .item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.primary .item:hover .label {
  color: #ffffff;
}
.primary .item:hover .icon svg {
  fill: #ffffff;
}
.primary .item:hover .arrow svg {
  fill: rgba(255, 255, 255, 0.9);
  transition: fill 0.2s ease;
}
.primary .item:active {
  background-color: rgba(0, 0, 0, 0.05);
}
.primary .item:active .label {
  color: #ffffff;
}
.primary .item.selected {
  background-color: rgba(0, 0, 0, 0.05);
}
.primary .item.selected .label {
  color: #ffffff;
}
.primary .item.selected .icon svg {
  fill: rgba(0, 0, 0, 0.3);
}
.primary .item.selected .arrow svg {
  fill: rgba(255, 255, 255, 0.9);
  transition: fill 0.2s ease;
}
.primary .item.selected .counter {
  background-color: #ffffff;
  color: #1761a6;
}