/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.contentGroup {
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
}
.contentGroup .title {
  display: flex;
  border-bottom: 1px solid rgba(58, 77, 96, 0.05);
  padding: 9px 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contentGroup .title .indicator {
  position: absolute;
  left: 2px;
  top: 5px;
  bottom: 5px;
  width: 3px;
  background-color: rgba(23, 97, 166, 0.1);
  border-radius: 0 20px 20px 0;
  transition: background-color 0.1s, width 0.1s ease-in-out;
}
.contentGroup .title .text {
  flex-grow: 1;
  font-weight: 600;
  transform: translateY(2px);
}
.contentGroup .title .counter {
  flex-shrink: 0;
  font-size: 12px;
  color: rgba(58, 77, 96, 0.8);
  background-color: rgba(234, 240, 245, 0.8);
  border-radius: 50px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
}
.contentGroup .content {
  padding: 20px;
}
.contentGroup:hover .title .indicator {
  background-color: rgba(23, 97, 166, 0.2);
}
.contentGroup:last-child {
  border-bottom: 0;
}

.colorNote .content {
  background-color: #ffffed;
  color: #777555;
}