/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ICON
   =================================================================== */
.base {
  line-height: 1;
  display: inline-block;
  vertical-align: inherit;
}
.base svg {
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
.base.presentation {
  display: block !important;
  border: 1px solid rgba(58, 77, 96, 0.1);
  border-radius: 3px;
  text-align: center;
  padding: 20px 5px;
  margin-bottom: 10px;
}
.base.presentation svg {
  fill: #1761a6;
}
.base.presentation svg {
  width: 24px;
  height: 24px;
}
.base.presentation .label {
  margin-top: 20px;
  display: block;
  color: rgba(58, 77, 96, 0.8);
}
.base.filled {
  width: 31px;
  height: 31px;
}
.base.filled svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.base.circle {
  border-radius: 50%;
}

.colorBase80 svg {
  fill: rgba(58, 77, 96, 0.8);
}

.colorBase60 svg {
  fill: rgba(58, 77, 96, 0.6);
}

.colorBase40 svg {
  fill: rgba(58, 77, 96, 0.4);
}

.colorInfo svg {
  fill: #4aa1db;
}
.colorInfo.filled {
  background-color: #4aa1db;
}
.colorInfo.filled svg {
  fill: #ffffff;
}

.colorPrimary svg {
  fill: #1761a6;
}
.colorPrimary.filled {
  background-color: #1761a6;
}
.colorPrimary.filled svg {
  fill: #ffffff;
}

.colorSuccess svg {
  fill: #36b189;
}
.colorSuccess.filled {
  background-color: #36b189;
}
.colorSuccess.filled svg {
  fill: #ffffff;
}

.colorWarning svg {
  fill: #ffb822;
}
.colorWarning.filled {
  background-color: #ffb822;
}
.colorWarning.filled svg {
  fill: #ffffff;
}

.colorError svg {
  fill: #d04b67;
}
.colorError.filled {
  background-color: #d04b67;
}
.colorError.filled svg {
  fill: #ffffff;
}

.colorContrast svg {
  fill: #ffffff;
}
.colorContrast.filled {
  background-color: #ffffff;
}
.colorContrast.filled svg {
  fill: #ffffff;
}

.colorSilent svg {
  fill: rgba(23, 97, 166, 0.3);
}
.colorSilent.filled {
  background-color: rgba(23, 97, 166, 0.3);
}
.colorSilent.filled svg {
  fill: #ffffff;
}

.sizeFill svg {
  width: 100%;
  height: 100%;
}

.sizeSmall svg {
  width: 14px;
  height: 14px;
}

.sizeMedium svg {
  width: 20px;
  height: 20px;
}
.sizeMedium.filled {
  width: 42px;
  height: 42px;
}

.sizeLarge svg {
  width: 24px;
  height: 24px;
}
.sizeLarge.filled {
  width: 50px;
  height: 50px;
}

.sizeXLarge svg {
  width: 45px;
  height: 45px;
}

.marginRight {
  margin-right: 5px;
}

.rotate {
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}