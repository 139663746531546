/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  height: 100%;
  overflow-y: auto;
  position: relative;
}
.base .container {
  z-index: 1;
  height: fit-content;
}
.base .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.base .empty .arrow {
  width: 80px;
  transform: rotate(110deg);
  margin: 50px 40px 50px 0;
}
.base .empty .arrow svg {
  fill: #1761a6;
}
.base .empty .text {
  padding: 0 30px;
  text-align: center;
}
.base.embedded div[role=treeitem]:last-child .item {
  box-shadow: none;
}

.item {
  height: 100%;
  cursor: pointer;
  display: flex;
  box-shadow: inset 0 -1px 0 0 rgba(58, 77, 96, 0.1);
  background: #ffffff;
}
.item .spacers {
  display: flex;
  flex-shrink: 0;
}
.item .spacers .spacer {
  width: 7px;
  height: 100%;
  background: rgba(23, 97, 166, 0.2);
}
.item .wrapper {
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  padding-left: 10px;
  flex-grow: 1;
  overflow: hidden;
}
.item .wrapper .caret {
  margin-right: 5px;
}
.item .wrapper .caret svg {
  fill: #1761a6;
}
.item .wrapper .caret svg {
  width: 12px;
  height: 12px;
}
.item .wrapper .icon {
  margin-right: 7px;
}
.item .wrapper .code {
  color: rgba(58, 77, 96, 0.5);
  padding-right: 5px;
}
.item .wrapper .label {
  color: #3a4d60;
  padding-right: 10px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item .wrapper .actions {
  padding-right: 2px;
}
.item:hover .wrapper {
  background-color: rgba(23, 97, 166, 0.03);
}
.item.isOpen .wrapper .caret {
  transform: rotate(0deg);
}
.item.isRoot {
  font-weight: 700;
}
.item.isDragging {
  height: 40px;
  width: 280px;
}
.item.level0 .wrapper .label {
  font-weight: 600;
}
.item.isSelected .spacers .spacer {
  background: rgba(23, 97, 166, 0.3);
}
.item.isSelected .wrapper {
  background-color: rgba(23, 97, 166, 0.05);
}
.item.isSelected .wrapper .code {
  color: #1761a6;
}
.adder {
  height: 40px;
  cursor: pointer;
  position: relative;
}
.adder .wrapper {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 1px dashed rgba(58, 77, 96, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.adder .wrapper svg {
  fill: rgba(58, 77, 96, 0.4);
}
.adder:hover .wrapper {
  border-color: rgba(58, 77, 96, 0.8);
}
.adder:hover .wrapper svg {
  fill: #1761a6;
}